import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/homepage/HomePage.vue"),
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    component: () => import("@/views/footer/TosPage.vue"),
    meta: { refresh: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/footer/PrivacyPage.vue"),
    meta: { refresh: true },
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: () => import("@/views/footer/DisclaimerPage.vue"),
    meta: { refresh: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/LoginPage.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/RegistPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
  },
  {
    path: "/reset/:email/:token",
    name: "reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
  },
  {
    path: "/verification",
    name: "Verification",
    component: () => import("@/views/auth/Verification.vue"),
  },
  {
    path: "/verification/:email/:otp",
    name: "VerificationLink",
    component: () => import("@/views/auth/VerificationLink.vue"),
  },
  {
    path: "/:module",
    name: "chat-page",
    component: () => import("@/views/chat-dashboard/ChatPage.vue"),
  },
  {
    path: "/admin/users",
    name: "UserManagement",
    component: () => import("@/views/admin/Users.vue"),
  },
  {
    path: "/admin/invitations",
    name: "InvitationCode",
    component: () => import("@/views/admin/InvitationCode.vue"),
  },
  {
    path: "/user/members",
    name: "ListMember",
    component: () => import("@/views/member/ListMember.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
